import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private subscriptions: Subscription[] = [];

  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(
    private userIdle: UserIdleService,
    private router: Router,
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {}

  async ValidaLogin(user: any, pass: any): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_url']}/}`;
    try {
      // return await this.httpClient.get(url, { headers: this.headers }).toPromise();
      return {
        status: true,
        code: 200,
        message: 'OK'
      };
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }


  logout(): void {
    localStorage.clear();
    localStorage.setItem("Reiniciado","no");
    console.log("hola");
    this.router.navigate(['/']);
    //window.location.reload();
 
    
  }

  idleUser(): void {
    this.userIdle.startWatching();

    this.subscriptions.push(this.userIdle.onTimerStart().subscribe(count => {
      const eventList = [
        'click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'
      ];
      eventList.map((event => {
        document.body.addEventListener(event, () => this.userIdle.resetTimer());
      }));
    }));

    this.subscriptions.push(this.userIdle.onTimeout().subscribe(() => {
      if (this.router.url !== '/') {
        this.userIdle.resetTimer();
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.logout();
      }
      else{
        window.location.reload();
      }
    }));
  }
}
