<div class="row">
  <div class="col-12 text-center mt-5">
    <h1>Ingreso Mantenedor</h1>

    <div class="row">
      <div class="col-6 offset-3">
        <input type="text" class="admtext form-control mt-5 mb-5" placeholder="INGRESE SU RUT" [formControl]="user" [ngClass]="{ 'is-invalid': user.value.length > 9 && !this.rutValid }" (focus)="desplegarRut('inputUser')" readonly > 
        <div *ngIf="user.value.length > 9 && !this.rutValid" class="invalid-feedback">
          <i class="far fa-times-circle"></i> Por favor, ingrese un rut válido
        </div>
      </div>
    
      <div class="col-6 offset-3">
        <input type="password" class=" admtext form-control mb-5" placeholder="INGRESE SU CLAVE" [formControl]="pass" (focus)="desplegarAlphaNumeric('inputPass')" readonly > 
      </div>
    
      <div class="col-6 offset-3">
        <button class="btn btn-primary admtext" (click)="onSubmit(user.value, pass.value)" >INGRESAR</button>
      </div>
    </div>
  </div>
</div>
<button class="btn btnl btnv" (click)="volver()">
  Volver
</button>


