<div class="hold-transition login-page">
    
    <div class="col-12 centrado-boton" (click)="LoginUsuario($event)" >
        <a class="btn btn-block btn-success w-100">
            Login<i class="fas fa-sign-in-alt ml-2"></i>
        </a>
    </div>

    <div class="btn-izq" (click)="izqbtn()">

    </div>

    <div class="btnder btn-izq" (click)="derbtn()">

    </div>
</div>