import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { Usuarios } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-usuarios-modal',
  templateUrl: './usuarios-modal.component.html',
  styleUrls: ['./usuarios-modal.component.scss']
})
export class UsuariosModalComponent implements OnInit {


  usuarios: Usuarios[]
  usuariosV2: [] = [];
  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface;
  onClose: Subject<boolean>;
  eUsuario: boolean;
  //@Input() regiones;

  constructor(private modalService: ModalService, private formBuilder: FormBuilder, private PruebasService: PruebasService, private SweetAlertService: SweetAlertService, private router: Router) { }

  ngOnInit(): void {
    this.iniciarDato()
    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  iniciarDato() {
    this.registerForm = new FormGroup({
      rUsuario: new FormControl(),
      nUsuario: new FormControl(),
      cUsuario: new FormControl(),
      eUsuario: new FormControl(),
    });
  }

  obtenerdatos() {
    console.log("FORM VALUE: ", this.registerForm.value);
    this.datosInterface = {
      "acc": "N",
      "dniUsu": this.registerForm.value.rUsuario,
      "nomUsu": this.registerForm.value.nUsuario,
      "pass": this.registerForm.value.cUsuario,
      "hab": this.registerForm.value.eUsuario === "true" ? true : false
    }
    console.log('Datos Interfaz: ', this.datosInterface);

  }
    async agregarUsuarios() {

    await this.obtenerdatos();
    //console.log(this.datosInterface);

    let datosRetornados = await this.PruebasService.crudUsuarios(this.datosInterface)
    console.log('RESPONSE: ', datosRetornados);
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("¡Usuario creado!");    
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se pudo crear el usuario, intente nuevamente 🙂!");
    }
  }


}
