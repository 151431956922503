import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pesoChileno'
})
export class PesoChilenoPipe implements PipeTransform {

  transform(value: any): string {
    return '$ ' + value.toString().replace(/(\.\d+)|\B(?=(\d{3})+(?!\d))/g, (g1: any) => {
      return g1 || '.';
    });
  }

}
