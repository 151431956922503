import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';

@Component({
  selector: 'app-vista-modal',
  templateUrl: './vista-modal.component.html',
  styleUrls: ['./vista-modal.component.scss']
})
export class VistaModalComponent implements OnInit {

  
  registerForm: FormGroup;
  submitted: boolean = false;
  @Input() zonas: { idZona: any; "": any; };

  constructor(private modalService: ModalService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    //this.validarFormulario();
    //this.cargarDatos();
    
  }

  get f() { return this.registerForm.controls; }

  cerrarModal(){
    this.modalService.closeModal();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    
    // display form values on success
}

  // validarFormulario()
  // {
  //   this.registerForm = this.formBuilder.group({

  //     firstName: ['', [Validators.required, Validators.minLength(2)]],
  //     pais: ['', Validators.required],
  //     // validates date format yyyy-mm-dd
  //     represent: ['', Validators.required],
  //     fecha: ['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
  //     status: ['', Validators.required],
  //     actividad: ['', Validators.required],
  //   } );
  // }

  // cargarDatos()
  // {
  //   this.registerForm.patchValue({
  //     idZona: this.zonas.idZona,
  //     zona: this.zonas.,
  //   })
  //   console.log('FORMULARIO: ', this.registerForm);
  //   this.registerForm.controls.firstName.disable();
    
  // }

}
