<div class="content-wrapper">
  <div class="container-fluid main">
    <div class="fade-in">
      <div class="card">
        <div class="card-header">
  
          <div class="row">
            <div class="col-sm-12 text-center">
  
              <h4 class="text-dark">Reporte de Servicios</h4>
  
              <app-header-table
              (changeAction)="getLogs($event)">
              </app-header-table>
  
            </div>
          </div>
        </div>
  
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 text-center display-4" *ngIf="dataDia.length === 0">
              <h4> Sin registros de reportes </h4>
            </div>
  
            <div class="col-sm-12 table-responsive" *ngIf="dataDia.length > 0">
              <app-datatable 
                [columnas]="columns" 
                [data]="dataDia" 
                [nameTabla]="nameTabla" 
                (changeAction)="action($event)">
              </app-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
