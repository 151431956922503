import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { AdminComponent } from './modules/admin/pages/admin.component';
import { CienteComponent } from './modules/ciente/ciente.component';
import { UsuarioComponent } from './modules/usuario/usuario.component';
import { LoginUsuarioComponent} from './modules/login-usuario/login-usuario.component';
import { TablaUsuariosComponent} from './modules/tabla-usuarios/tabla-usuarios.component';

import { TablaPedidosComponent } from './modules/tabla-pedidos/tabla-pedidos.component';
import { VistaGeneralComponent } from './modules/vista-general/vista-general.component';
import {VistaModalComponent} from './modules/vistaModal/vista-modal/vista-modal.component';
import { combineAll } from 'rxjs/operators';
import { from } from 'rxjs';
import { ReporteCierreComponent } from './modules/reportes/components/reporte-cierre/reporte-cierre.component';
import { ReporteServicesComponent } from './modules/reportes/components/reporte-services/reporte-services.component';



// import { LockerComponent } from './modules/shared/locker/locker/locker.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'cliente', component: CienteComponent },
  { path: 'usuario', component: UsuarioComponent },
  { path: 'LoginUser', component: LoginUsuarioComponent },
  { path: 'TablaUser', component: TablaUsuariosComponent},
  { path: 'TablaPedido', component: TablaPedidosComponent},
  { path: 'VistaGeneral', component: VistaGeneralComponent},
  { path: 'VistaModal', component: VistaModalComponent},
  { path: 'reporte-log', component: ReporteServicesComponent },
  { path: 'cierre', component: ReporteCierreComponent }


  // { path: 'Locker', component: LockerComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
