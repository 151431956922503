import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { RutService } from 'src/app/core/services/http/rut.service';

@Component({
  selector: 'app-modal-log',
  templateUrl: './modal-log.component.html',
  styleUrls: ['./modal-log.component.scss']
})
export class ModalLogComponent implements OnInit {
  data: any;
  detalleLog: any;

  constructor(
    private modalService: ModalService,
    private datePipe: DatePipe,
    private rutService: RutService
  ) { }

  ngOnInit(): void {
    this.formatJson();
  }

  formatJson(): void {
    const rut = this.data.rutCli ?? this.data.rutCliente;
    this.detalleLog = {
      idSession: this.data.idSession,
      rut: rut ? this.rutService.formateaRut(rut) : '-',
      fecha: this.datePipe.transform(this.data.fecha, 'dd/MM/yyyy'),
      hora: this.data.hora ?? this.datePipe.transform(this.data.fecha, 'HH:mm:ss'),
      endpoint: this.data.endPoint,
      request: this.isJson(this.data.request) ? JSON.parse(this.data.request) : this.data.request,
      response: this.isJson(this.data.response) ? JSON.parse(this.data.response) : this.data.response
    };
  }

  export(): void {
    const rut = this.detalleLog.rut?.replace(/\./g, '') ?? '';
    const filename = `${rut}-${this.data.fecha}`;
    this.download(JSON.stringify(this.detalleLog, null, 2), filename, 'application/json; charset=utf-8');
  }

  download(content: any, filename: string, contentType: string): void {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
  }

  closeModal(): void {
    this.modalService.closeModal();
  }

  isJson(item): boolean {
    item = typeof item !== 'string'
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === 'object' && item !== null) {
      return true;
    }

    return false;
  }
}
