import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seleccion-empresa',
  templateUrl: './seleccion-empresa.component.html',
  styleUrls: ['./seleccion-empresa.component.scss']
})
export class SeleccionEmpresaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
