
<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 87vh!important;">
    <h5 class="card-header" class="text-secondary">..::CARGAR::..</h5>
    <div class="card-body">
        <form [formGroup]="registerForm"  class="text-secondary">
            <div class="form-group">
                <label for="exampleInputPassword1">Ingrese la contraseña del pedido</label>
                <input type="password" id="clave" formControlName="passWrd" class="form-control" name="password">
              </div>
        </form>        
    </div>
    <form><div class="text-center">
        <button class="btn btn-primary mr-1" type="submit" (click)="cargarPedido()">GUARDAR</button>
        <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
    </div></form>
</div>
