import { Component, OnInit } from '@angular/core';
import { TarjetaLockerService } from '../../../../core/services/http/tarjeta-locker.service';
import { SweetAlertService } from '../../../../core/services/sweet-alert/sweet-alert.service';
import { PruebasService } from '../../../../core/services/http/pruebas.service';

@Component({
  selector: 'app-locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.scss']
})
export class LockerComponent implements OnInit {

  Locker;
  pedidos;
  constructor(private TarjetaLockerService: TarjetaLockerService, private SweetAlertService: SweetAlertService, private PruebasService: PruebasService) { }

  ngOnInit(): void {
   // this.TarjetaLockerService.Conectar();
    this.CargarLocker();
  }

  async CargarLocker() {
    this.Locker = await this.TarjetaLockerService.DibujoCasillero();
    console.log(this.Locker);

    var respuesta = await this.PruebasService.traerCasilleros();
    respuesta = respuesta["dataCas"];
    //console.log(respuesta);
    this.pedidos = await respuesta.filter(function (array) {
      if (array["idLocker"] == 7 && array["idOficina"] == 6) {
        return array
      }
    });

    this.Locker.forEach(columnas => {
     // console.log(columnas.Casilleros);
      
      columnas.Casilleros.forEach(casillero => {
        this.pedidos.forEach(estadocas => {
          if (casillero.id == estadocas.idCasillero && estadocas.sEstado != "V") {
            casillero.ocupado = true;
          }
        });
      });
      
    });
    console.log("pedidos:",this.pedidos);
    console.log("Locker:",this.Locker);
  }

  async AbrirLocker(id) {
    var pedido;
    pedido = this.pedidos.filter(function (array) {
      if (array.casilleros[0].idCasillero == id) {
        return array
      }
    });
    //console.log(pedido);
    if (pedido.length > 0) {
      var respapert = await this.SweetAlertService.swalConfirmApertura(pedido[0].productos[0].codigoEtiqueta)
      if (respapert) {
        this.casosPuerta(id);
      }
    }
    else {
      this.casosPuerta(id);
    }
  }

  async casosPuerta(id) {
    
    if (id != 29) {
      this.SweetAlertService.CargandoDatos();
      var req = {
        "idPuerta": parseInt(id)
      }
      //console.log(req);
      
      var respuesta = await this.TarjetaLockerService.AbrirPuerta(req);
      this.SweetAlertService.swalClose();
      if (respuesta["status"] == false) {
        this.SweetAlertService.ErrorPuerta("");
      }
    }
    else {
      this.SweetAlertService.PuertaPc("");
    }
  }

}
