import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReporteriaService } from 'src/app/core/services/http/reporteria.service';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { RutService } from 'src/app/core/services/http/rut.service';
import { ModalLogComponent } from '../modal-log/modal-log.component';

@Component({
  selector: 'app-reporte-services',
  templateUrl: './reporte-services.component.html',
  styleUrls: ['./reporte-services.component.scss']
})
export class ReporteServicesComponent implements OnInit {
  dataDia: any[] = [];
  nameTabla!: string;
  columns: any[] = [];

  constructor(
    private sweetAlertService: SweetAlertService,
    private reporteriaService: ReporteriaService,
    private datePipe: DatePipe,
    private rutService: RutService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.getColumnas();
  }

  getColumnas(): void {
    this.columns = [
      { title: 'ID totem', data: 'idTotem' },
      { title: 'ID Session', data: 'idSession', visible: false },
      { title: 'Rut', data: 'rutCli', width: '8%', render: (data: any): any => data ? this.rutService.formateaRut(data) : data },
      { title: 'Nombre Completo', data: 'nombreCompleto', visible: false },
      { title: 'Teléfono', data: 'telefono', visible: false },
      { title: 'Email', data: 'email', visible: false },
      { title: 'Fecha', data: 'fecha', render: (data: any): any => this.datePipe.transform(data, 'dd/MM/yyyy') },
      { title: 'Hora inicio', data: 'horaSession' },
      { title: 'Hora fin', data: 'fecha', render: (data: any): any => this.datePipe.transform(data, 'HH:mm:ss') },
      { title: 'Tipo Servicio', data: 'tipo' },
      { title: 'Modulo', data: 'modulo' },
      { title: 'Motivo', data: 'servicio', orderable: false },
      { title: 'Descripción', data: 'detalle', orderable: false },
      { title: 'URL servicio', data: 'endPoint', visible: false },
      { title: 'Datos de entrada', data: 'request', visible: false },
      { title: 'Datos de salida', data: 'response', visible: false },
      {
        title: 'Estado',
        data: 'status',
        width: '10%',
        className: 'dt-center',
        render: (data): any => {
          const icon = data ? '<i class="fas fa-check-circle text-success"></i>' : '<i class="fas fa-times-circle text-danger"></i>';
          const button = `<span class="d-none">${data ? 'Exito' : 'Error'}</span> ${icon}`;
          return button;
        }
      },
      {
        title: 'Detalle',
        orderable: false,
        width: '10%',
        className: 'dt-center',
        render: (data, type, row): any => {
          return `<button class="btn btn-primary btn-sm btn-export-log" type="submit" value='${JSON.stringify(row).replace(/'/g, '')}'>
                  <i class="c-icon fas fa-eye"></i>
                </button>`;
        }
      }
    ];
  }

   async getLogs(data: any): Promise<void> {
     this.sweetAlertService.swalLoading();
     this.dataDia = [];

     const response = await this.reporteriaService.ResumenPedidos(data);
     this.sweetAlertService.swalClose();

     if (response['status']) {
       this.dataDia = response['data'];
       const totem = data.idTotem === 0 ? '' : `_Kiosko-${data.idTotem}`;
       this.nameTabla = `Reporte de LOG_Oficina-${data.idOficina}${totem}_${data.fechaIn}_${data.fechaFin}`;
     }
   }

  action(e: any): void {
    if (e.action === 'log') {
      this.detalleLog(JSON.parse(e.value));
    }
  }

  detalleLog(data: any): void {
    const options = {
      class: 'modal-xl',
      ignoreBackdropClick: true,
      initialState: { data }
    };
    this.modalService.openModal(ModalLogComponent, options);
  }

}
