import { Injectable, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  @Output() confirmation: EventEmitter<any> = new EventEmitter();
  constructor(private Router:Router) { }

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
  });

  async errorToast(message: string): Promise<void> {
    await this.toast.fire({
      icon: 'error',
      title: message
    });
  }

  async succesToast(message: string): Promise<void> {
    await this.toast.fire({
      icon: 'success',
      title: message
    });
  }

  swalClose(): void {
    Swal.close();
  }

  async swalSuccess(text: string): Promise<boolean> {
    let response = false;
    Swal.fire({
      title: text,
      confirmButtonColor: '#00AA9F',
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    })
    return response;
  }

  async swalInfo(message: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'info',
      text: message,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
        content: 'text-orange-isa'
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalError(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: 'Lo sentimos!',
      text,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalErrorgeneral(titulo: string,text:string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: titulo,
      text:text,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async SinProducto(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: 'Producto no Encoontrado!',
      text,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async ErrorPuerta(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: 'Error al abrir puerta!',
      text: "Por favor comuniquese con algun empleado.",
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async PuertaPc(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: 'Error al abrir puerta!',
      text: "Esta Puerta no puede ser aierta.",
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalConfirmCarga(data): Promise<boolean> {
    let response = false;
    await Swal.fire({
      title: '¿Realizar Carga?',
      text: '¡Se realizara la carga del producto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36ABAC',
      cancelButtonColor: '#E06162',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Cargar'
    }).then((result) => {
      if (result.value) {
        response = true;
      }
      else{
        response = false;
      }
    });
    return response;
  }

  async swalConfirmApertura(data): Promise<boolean> {
    let response = false;
    await Swal.fire({
      title: '¿Realizar Apertura?',
      text: "Esta puerta contiene el pedido " + data + " ¿Desea Abrirlo?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36ABAC',
      cancelButtonColor: '#E06162',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.value) {
        response = true;
      }
      else{
        response = false;
      }
    });
    return response;
  }

 async  swalConfirmRetiro(data): Promise<boolean> {
    let response = false;
    await Swal.fire({
      title: '¡Producto Encotrado!',
      text: '¡Se realizara apertura de la puerta!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36ABAC',
      cancelButtonColor: '#E06162',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Retirar'
    }).then((result) => {
      if (result.value) {
        if (result.value) {
          response = true;
        }
        else{
          response = false;
        }
      }
    });
    return response;
  }

  swalErrorService(text: string): void {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos!',
      text,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-exit',
      }
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        this.Router.navigate(['']);
      }
    });
  }

  swalPrint(): void {
    Swal.fire({
      title: 'IMPRIMIENDO',
      imageUrl: 'assets/img/print.gif',
      imageAlt: 'Imprimiendo',
      imageHeight: 249,
      imageWidth: 224,
      width: '100%',
      position: 'center',
      showConfirmButton: false,
      allowOutsideClick: false,
      customClass: {
        title: 'text-money medium-blue mb-0',
      }
    });
  }

  swalLoading(): void {
     Swal.fire({
      title: 'Cargando...',
      imageUrl: 'assets/img/carga.gif',
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(255,255,255,0.4)',
      allowOutsideClick: false,
      customClass: {
        popup: '',
      }
    });
  }

  swalLeyendoQR(): void {
    Swal.fire({
     title: 'Leyendo QR Por favor Espere',
     imageUrl: 'assets/img/carga.gif',
     position: 'center',
     showConfirmButton: false,
     imageHeight: 70,
      imageWidth: 300,
     background: 'bottom',
     backdrop: 'rgba(255,255,255,0.8)',
     allowOutsideClick: false,
     customClass: {
       popup: '',
     }
   });
 }
 CargandoDatos(): void {
  Swal.fire({
   title: 'Cargando Datos Por favor Espere',
   imageUrl: 'assets/img/carga.gif',
   position: 'center',
   showConfirmButton: false,
   imageHeight: 70,
    imageWidth: 300,
   background: 'bottom',
   backdrop: 'rgba(255,255,255,0.8)',
   allowOutsideClick: false,
   customClass: {
     popup: '',
   }
 });
}

  swallTomarDatos(texto): void {
    Swal.fire({
     title: texto,
     imageUrl: 'assets/img/carga.gif',
     position: 'center',
     showConfirmButton: false,
     background: 'bottom',
     backdrop: 'rgba(255,255,255,0.85)',
     allowOutsideClick: false,
     customClass: {
       popup: '',
     }
   });
 }

  async swalUbicacion(body: any): Promise<boolean> {
    let response;
    await Swal.fire({
      // imageUrl: body,
      html: '<svg xmlns="http://www.w3.org/2000/svg" style="width: 55%;" viewBox="0 0 310.46 310.46"><defs><style>.cls-1{fill:#366170;}.cls-2{fill:#f3d383;}.cls-3{fill:#509881;}.cls-4{fill:#cb7e6e;}.cls-5{fill:#708696;}.cls-6{fill:#e2b374;}</style></defs>' +
              '<g>' + body + '</g>' +
            '</svg>',
      titleText: 'UBICACIÓN SELECCIONADA',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: 'inherit',
      cancelButtonColor: 'inherit',
      cancelButtonText: '<div class="center mt-5" style="margin-left: 5rem;">' +
                          '<div class="hexagon"></div>' +
                          '<div class="text-hexa">' +
                            '<i class="fas fa-arrow-circle-left" style="font-size: 64px;"></i>' +
                            '<p style="font-size: 20px;margin-top: 10px;">INGRESAR OTRO</p>' +
                          '</div>' +
                        '</div>',
      confirmButtonText:  '<div class="center mt-5" style="margin-left: 2rem;">' +
                            '<div class="hexagon"></div>' +
                            '<div class="text-hexa">' +
                              '<i class="fas fa-arrow-circle-right" style="font-size: 64px;"></i>' +
                              '<p style="font-size: 20px;margin-top: 10px;">CONTINUAR</p>' +
                            '</div>' +
                          '</div>',
      customClass: {
      //   popup: 'modal-content',
      //   cancelButton: 'btn-exit',
      // confirmButton: '',
      }
    }).then((result) => {
      if (result.value) {
        response = result.value;
      }
    });
    return response;
  }

  // Swall de We Store consuta
  async ConsultaReserLocker(Titulo,mensaje,cancel,btnacept): Promise<boolean> {
    let response = false;
    await Swal.fire({
      title: Titulo,
      text: mensaje,
      icon: 'info',
      showCancelButton: cancel,
      confirmButtonColor: '#36ABAC',
      cancelButtonColor: '#E06162',
      cancelButtonText: 'No realizar',
      confirmButtonText: btnacept
    }).then((result) => {
      if (result.value) {
        response = true;
      }
      else{
        response = false;
      }
    });
    return response;
  }

}
