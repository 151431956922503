<!-- <app-header></app-header> -->

<div class="hold-transition sidebar-mini layout-fixed">
    <div class="wrapper">
        <!-- Preloader -->
        <div class="preloader flex-column justify-content-center align-items-center">
            <img class="animation__bounce" src="assets/img/logo.svg" alt="AdminLTELogo" height="150" width="150">
        </div>
        <app-sidebar *ngIf="ubicarcionUrl() != '/' && ubicarcionUrl() != '/LoginUser'"></app-sidebar>
        <router-outlet></router-outlet>
        <footer class="main-footer">
            <div class="mt-5"> <b>Version</b> 0.0.1</div>
            <img class="mt-n4 float-right" src="assets/img/powered-ttp.svg">
        </footer>
    </div>
    <!-- ./wrapper -->
</div>
<!-- <app-footer></app-footer> -->