<!-- <div class="container-fluid overflow-auto">
    <!-- <button (click)="volver()">Volver</button>
      <h2>Zonas</h2>
      <p>A continuación, se muestran los pedidos con la busqueda</p> 
      <button type="submit" (click)="TablaUser()">Añadir...</button> ----
    <button (click)="volver()">Volver</button>
    <h5>Admi Usuarios</h5>
    <p>Para modificar presione el nombre en la tabla.</p>
    <button type="submit" class="btn btn-primary btn-block" (click)="Seleccionar()">
      Registrar
    </button>
    <div *ngIf="usuariosV2">
      <p-table #dt1 [value]="usuariosV2" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['sDniUsu','sNombreUsu','sPassUsu','bHab']">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="Palabra clave" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">Rut Usuario</div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">Nombre</div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">Contraseña</div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">Estado</div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">Editar</div>
            </th>
            <th>
                <div class="p-d-flex p-jc-between p-ai-center">Eliminar</div>
              </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr>
            <td>
              {{ customer.sDniUsu }}
            </td>
            <td>
              {{ customer.sNombreUsu }}
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="password" [(ngModel)]="customer.sPassUsu" />
                </ng-template>
                <ng-template pTemplate="output">
                  <div>
                    ****
                  </div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              {{ customer.bHab }}
            </td>
            <td (click)="traerdatos(customer)" class="pointer">
                Actualizar
              </td>
              <td (click)="deleteSelected(customer)" class="pointer">
                Quitar
              </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="10">No hay Usuarios para mostrar.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <br />
    <br />
    <br />
    <br />
  </div> -->

  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-auto">
            <h1 class="m-0 pl-4 subtitle">Módulo de Administrativos</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6 mt-1 border-left border-right">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item"><a href="#">Admin Usuarios</a></li>
              <li class="breadcrumb-item active">Administrativos</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
  
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
  
        <!-- Main row -->
        <div class="row">
          <!-- Left col -->
          <section class="col-lg-12 pl-3 pr-3 connectedSortable">
  
            <div class="card  box">
              <div class="row p-3">
                <div class="col-12">
                  <h5>Asignar Usuarios Administrativos</h5>
                  <button type="button" (click)="Seleccionar()" class="btn btn-block btn-success"><i class="fas fa-cogs"></i> Agregar
                    Nuevos usuarios administrativos</button>
                </div>
                <div class="col-12"> 
                  <!-- table table-responsive table-hover mt-2 -->
                  <div *ngIf="usuariosV2">
                    <p-table #dt1 [value]="usuariosV2" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                      [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" styleClass="p-datatable-customers p-datatable-gridlines"
                      [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      [globalFilterFields]="['sDniUsu','sNombreUsu','sPassUsu','bHab']">
                      <ng-template pTemplate="caption">
                        <div class="p-d-flex">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                              placeholder="Buscar en Tabla" />
                          </span>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr>
                          <th>
                            <div class="p-d-flex p-jc-between p-ai-center">Rut Usuario</div>
                          </th>
                          <th>
                            <div class="p-d-flex p-jc-between p-ai-center">Nombre</div>
                          </th>
                          <th>
                            <div class="p-d-flex p-jc-between p-ai-center">Contraseña</div>
                          </th>
                          <th>
                            <div class="p-d-flex p-jc-between p-ai-center">Estado</div>
                          </th>
                          <th>
                            <div class="p-d-flex p-jc-between p-ai-center">Act</div>
                          </th>
                          <th>
                              <div class="p-d-flex p-jc-between p-ai-center">Del</div>
                            </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-customer>
                        <tr>
                          <td>
                            {{ customer.sDniUsu }}
                          </td>
                          <td>
                            {{ customer.sNombreUsu }}
                          </td>
                          <td pEditableColumn>
                            <p-cellEditor>
                              <ng-template pTemplate="input">
                                <input pInputText type="password" [(ngModel)]="customer.sPassUsu" />
                              </ng-template>
                              <ng-template pTemplate="output">
                                <div>
                                  ****
                                </div>
                              </ng-template>
                            </p-cellEditor>
                          </td>
                          <td>
                            <div *ngIf="customer.bHab">Habilitado</div>
                            <div *ngIf="!customer.bHab">Deshabilitado</div>     
                          </td>
                          <td (click)="traerdatos(customer)" style="cursor: pointer;">
                            <i class="fas fa-edit"></i>
                            </td>
                            <td (click)="deleteSelected(customer)" style="cursor: pointer;">
                              <i class="fas fa-trash-alt"></i>
                            </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="10">No hay Usuarios para mostrar.</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
  
            </div>
  
          </section>
          <!-- /.Left col -->
          <!-- right col (We are only adding the ID to make the widgets sortable)-->
  
          <!-- right col -->
        </div>
        <!-- /.row (main row) -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>