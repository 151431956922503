import { Component, OnInit } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Component({
  selector: 'app-ciente',
  templateUrl: './ciente.component.html',
  styleUrls: ['./ciente.component.scss']
})
export class CienteComponent implements OnInit {

  constructor(private Router:Router) { }
  casillero;
  ngOnInit(): void {
    this.casillero = localStorage.getItem("casillero");
  }

  volver(){
    this.Router.navigate(['']);
  }

}
