<!DOCTYPE html>
<html lang="en">
<head>
  <title>Api Lockers Web</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>
<body>


    
  
<div class="container-fluid overflow-auto" style="height: 87vh!important;">

    <nav class="navbar navbar-default">
        <div class="container-fluid">
          <div class="navbar-header">
            <a class="navbar-brand" style="border: 1px solid black;">APILOCKERS</a>
          </div>
          <ul class="nav navbar-nav">
            <li class="active"><a href="#">INICIO</a></li>
            <li><a href="TablaZona">ZONAS</a></li>
            <li><a href="TablaRegiones">REGIONES</a></li>
            <li><a href="TablaComunas">COMUNAS</a></li>
            <li><a href="TablaOficinas">OFICINAS</a></li>
            <li><a href="TablaClientes">CLIENTES</a></li>
            <li><a href="TablaUser">USUARIOS</a></li>
            <li><a href="TablaUsuariosOfi">USUARIOS OFICINAS</a></li>
            <li><a href="TablaLockers">LOCKERS</a></li>
            <li><a href="TablaCasilleros">CASILLEROS</a></li>
            <li><a href="TablaEstado">ESTADO</a></li>
          </ul>
        </div>
      </nav>
  <h3>Basic Navbar Example</h3>
  <p>A navigation bar is a navigation header that is placed at the top of the page.</p>
</div>

</body>
</html>
