import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PesoChilenoPipe } from 'src/app/core/pipes/peso-chileno.pipe';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import {ReporteriaService} from 'src/app/core/services/http/reporteria.service'
// import {  } from 'src/app/core/services/http/pagos.service';

@Component({
  selector: 'app-reporte-cierre',
  templateUrl: './reporte-cierre.component.html',
  styleUrls: ['./reporte-cierre.component.scss']
})
export class ReporteCierreComponent implements OnInit {
  dataCierre: any[] = [];
  nameTabla!: string;
  columns: any[] = [];

  constructor(
    private sweetAlertService: SweetAlertService,
    private pesoChilenoPipe: PesoChilenoPipe,
    private datePipe: DatePipe,
    private reporteriaService: ReporteriaService
    // private pagosService: PagosService
  ) { }

  ngOnInit(): void {
    this.getColumnas();
  }

  getColumnas(): void {


    // { title: 'Id Sesion', data: 'idSession', className: 'dt-center' },
    // { title: 'Fecha', data: 'fecha', render: (data: any): any => this.datePipe.transform(data, 'dd/MM/yyyy') },
    // { title: 'Hora', data: 'hora', className: 'dt-center' },
    // { title: 'N° Boleta', data: 'numeroBoleta', className: 'dt-center' },
    // {
    //   title: 'Monto Total',
    //   data: 'monto',
    //   className: 'dt-head-center dt-body-right',
    //   render: (data: any): any => this.pesoChilenoPipe.transform(data)
    // },
    // {
    //   title: 'Monto Cuotas',
    //   data: 'valorCuota',
    //   className: 'dt-head-center dt-body-right',
    //   render: (data: any): any => this.pesoChilenoPipe.transform(data)
    // },
    // { title: 'N° Cuotas', data: 'numeroCuotas', className: 'dt-center' },
    // { title: 'Tipo Tarjeta', data: 'tipoTarjeta', className: 'dt-center' },
    // { title: 'Marca Tarjeta', data: 'abreviacionTarjeta', className: 'dt-center' }
    this.columns = [
      // { title: 'Id Pedido', data: 'idpedido', className: 'dt-center', orderable: true },
      // { title: 'Id Locker', data: 'idLocker', className: 'dt-center', orderable: true },
      // { title: 'Id Oficina', data: 'idOficina', className: 'dt-center', orderable: true },
      { title: 'Casillero', data: 'idCasillero', className: 'dt-center', orderable: true },
      { title: 'Rut Cliente', data: 'sDniCli', className: 'dt-center', orderable: true },
      // { title: 'Fecha Registro', data: 'fechReg', render: (data: any): any => this.datePipe.transform(data, 'dd/MM/yyyy') },
      { title: 'Correo', data: 'email', className: 'dt-center', orderable: true},
      { title: 'Fecha Ingreso', data: 'fechIn', render: (data: any): any => this.datePipe.transform(data, 'dd/MM/yyyy') },
      { title: 'Fecha Retiro', data: 'fechOut', render: (data: any): any => this.datePipe.transform(data, 'dd/MM/yyyy') },
      // { title: 'Fecha Vencimiento', data: 'fechVen', render: (data: any): any => this.datePipe.transform(data, 'dd/MM/yyyy') },
      { title: 'Clave Pedido', data: 'claveCliente', className: 'dt-center', orderable: true },

    ];
  }

  async getTransaction(data: any): Promise<void> {
    console.log("CIERRE: ");
    
    this.sweetAlertService.swalLoading();
    this.dataCierre = [];
    const response = await this.reporteriaService.ResumenPedidos(data);
    this.sweetAlertService.swalClose();
    console.log("response CIERREE ", response);
    

    if (response['status']) {
      this.dataCierre = response['data'];
      const totem = data.idTotem === 0 ? '' : `_Kiosko-${data.idTotem}`;
      this.nameTabla = `Reporte de Cierre_Oficina-${data.idOficina}${totem}_${data.fechaIn}_${data.fechaFin}`;
    }
  }

}
