<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Detalle</h5>
    <button type="button" class="close" (click)="closeModal()">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="detalleLog">
    <div class="row">
      <div class="col-12 mb-5 text-right">
        <button type="button" class="btn btn-primary" (click)="export()">Exportar</button>
      </div>
      <div class="col-12 table-responsive">
        <div class="mb-5" style="display: table; width: 100%;">
          <div style="display: table-row-group;">
            <div style="display: table-row; background-color: #0000150d;">
              <div class="divTableCell font-weight-bold">Id sesión</div>
              <div class="divTableCell font-weight-bold">RUT</div>
              <div class="divTableCell font-weight-bold">Fecha</div>
              <div class="divTableCell font-weight-bold">Hora fin</div>
              <div class="divTableCell font-weight-bold">Servicio</div>
              <div class="divTableCell font-weight-bold">Datos de entrada</div>
              <div class="divTableCell font-weight-bold">Datos de salida</div>
            </div>
            
            <div style="display: table-row">
              <div class="divTableCell col-sm-1">{{ detalleLog.idSession }}</div>
              <div class="divTableCell col-sm-2">{{ detalleLog.rut }}</div>
              <div class="divTableCell col-sm-1">{{ detalleLog.fecha }}</div>
              <div class="divTableCell col-sm-1">{{ detalleLog.hora }}</div>
              <div class="divTableCell col-sm-1">{{ detalleLog.endpoint }}</div>
              <div class="divTableCell col-sm-3" style="white-space: pre">{{ detalleLog.request ? (detalleLog.request | json) : '-'}}</div>
              <div class="divTableCell col-sm" style="white-space: pre">{{ detalleLog.request ? (detalleLog.response | json) : '-'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>