import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ReporteriaService } from 'src/app/core/services/http/reporteria.service';
import { ConfigService } from '../../../../core/services/config/config.service';

@Component({
  selector: 'app-header-table',
  templateUrl: './header-table.component.html',
  styleUrls: ['./header-table.component.scss']
})
export class HeaderTableComponent implements OnInit {
  @Output() changeAction = new EventEmitter<any>();
  formulario!: FormGroup;
  oficinas: any[] = [];
  kioskos: any[] = [];
  maxDate: any;
  isSubmitted = false;
  path!: string;
  idTot: any;
  idOf: any;

  constructor(
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private sweetAlertService: SweetAlertService,
    private reporteriaService: ReporteriaService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.traerDatosConfig();
    this.path = this.route.snapshot.routeConfig?.path || '';
    this.maxDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
    this.initForm();
    this.getSucursales();
  }

  get f(): any { return this.formulario.controls; }

  async traerDatosConfig()
  {
    const API_URL = await this.configService.getConfig();
    this.idTot = API_URL['idTotem'];
    this.idOf = API_URL['idOficina'];
    console.log("ID TOTEM CONFIG: ", this.idTot);
    console.log("ID OFICINA CONFIG: ", this.idOf);
    
  }

  initForm(): void {
    this.formulario = this.formBuilder.group({
      fechaIn: ['', Validators.required],
      fechaFin: ['', Validators.required],
      idOficina: 1,
      idLocker: 1
    });
    this.formulario.patchValue({ fechaIn: this.datePipe.transform(Date.now(), 'yyyy-MM-dd') });
    this.formulario.patchValue({ fechaFin: this.datePipe.transform(Date.now(), 'yyyy-MM-dd') });
    console.log("Formulario: ", this.formulario);
    
  }

  async getSucursales(): Promise<void> {
    this.sweetAlertService.swalLoading();
    const response = await this.reporteriaService.ListarOficina();
    this.sweetAlertService.swalClose();

    if (response['status']) {
      const sucursales = response['data'];
      this.oficinas = sucursales.filter((value) => value.estado);
    }
  }

  async getKioskos(): Promise<void> {
    if (this.f.idOficina.valid) {
      const response = await this.reporteriaService.ListarTotem(this.f.idOficina.value);

      if (response['status'] && response['data']) {
        const kioskos = response['data'];
        this.kioskos = kioskos.filter((value) => value.estado);
      }
    }
  }

  action(): void {
    this.getKioskos();
    this.formulario.valid ? this.changeAction.emit(this.formulario.value) : this.isSubmitted = true;
  }
}
