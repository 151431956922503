import { Component, OnInit } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  constructor(private Router:Router) { }

  casillero;
  ngOnInit(): void {
    this.casillero = localStorage.getItem("casillero");
  }

  volver(){
    this.Router.navigate(['']);
  }
}
