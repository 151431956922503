<div class="d-flex  flex-column baseqr">
    <div class="qrimg"></div>

    <input type="text" class="admtext ingreserut form-control mt-5 mb-5" placeholder="INGRESE SU RUT" [formControl]="user"  (focus)="desplegarRut('inputUser')">
    <div *ngIf="rutvalido == false" class="errorrut">
      <i class="far fa-times-circle"></i> Por favor, ingrese un rut válido
    </div>

    <div class="textoQR"><span>Escanee su Rut o digíitelo presionando el campo de texto</span></div>
</div>

<button class="btn btnl btnvl" (click)="volver()">
    Volver
</button>

<button class="btn btnl btnAc" (click)="volver()">
    Aceptar
</button>