<div class="card m-3">
    <h5 class="card-header" class="text-secondary">EDITAR DATOS</h5>
    <div class="card-body">
        <form [formGroup]="registerForm"  class="text-secondary">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>ID ZONA</label>
                    <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">ID Zona es requerida</div>
                    </div>
                </div>
                <div class="form-group col-5">
                    <label>ZONA</label>
                    <input type="text" formControlName="pais" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pais.errors }" />
                    <div *ngIf="submitted && f.pais.errors" class="invalid-feedback">
                        <div *ngIf="f.pais.errors.required">ZONA es requerida</div>
                    </div>
                </div>
                
            </div>
            <div class="text-center">
                <button class="btn btn-primary mr-1" type="submit" (click)="onSubmit()">GUARDAR</button>
                <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
            </div>
        </form>
    </div>
</div>