import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vista-general',
  templateUrl: './vista-general.component.html',
  styleUrls: ['./vista-general.component.scss']
})
export class VistaGeneralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
