<!DOCTYPE html>
<html lang="en">
<head>
  <title>Tabla Pedidos</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
</head>
<body>
  <button (click)="volver()">Volver</button>
  <div class="container-fluid overflow-auto" style="height: 87vh!important;">
    <table class="table table">
      <tr>
        <th>ID Pedido</th>
        <th>ID Locker</th>
        <th>ID Oficina</th>
        <th>ID Casillero</th>
        <th>Hora y fecha de registro</th>
      </tr>
      <tr *ngFor="let asignados of pedidosV2">
        <td>{{ asignados.idpedido }}</td>
        <td>{{ asignados.idLocker }}</td>
        <td>{{ asignados.idOficina }}</td>
        <td>{{ asignados.idCasillero }}</td>
        <td>{{ asignados.fechReg | date:'hh:mm:ss - dd/MM/yyyy'}}</td>
        <td>
          <button class="btn btn-sm btn-primary" (click)="Seleccionar(asignados)">Seleccionar</button>
        </td>
      </tr>
    </table>
  </div>
</body>
</html>






