import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() step: string;
  urgencia = false;
  ayuda = false;
  sitio = "";

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  change(e: any, accion: string): void {
    e.preventDefault();
    if (accion === 'urgencia') {
      this.urgencia = true;
      this.ayuda = false;
    } else {
      this.ayuda = true;
      this.urgencia = false;
    }
  }

  //REPORTERIA
  redireccionReporteriaCierre()
  {
    this.router.navigate(['cierre']);
  }
  redireccionReporteriaLog()
  {
    this.router.navigate(['reporte-log']);
  }
  redireccionUsabilidad()
  {
    // this.router.navigate(['Usabilidad']);
  }
  RenviarCorreo(){
    this.router.navigate(['ReenviarCorreo']);
  }

  redireccionParaPedido()
  {
    this.router.navigate(['NuevoPedido']);
  }
  Dashboard()
  {
    this.router.navigate(['MenuOpciones']);
  }
  RealizarReserva()
  {
    this.router.navigate(['RealizarReserva']);
  }
  redireccionParaRetiro()
  {
    this.router.navigate(['RetirarPedido'],{ queryParams: { 'idcasillero': '5', 'idLocker': '5', 'idOficina':'5' } });
  }
  redireccionTablaPedidos()
  {
    this.router.navigate(['TablaPedido']);
  }
  redireccionTablaZona()
  {
    this.router.navigate(['TablaZona']);
  }
  redireccionTablaRegiones()
  {
    this.router.navigate(['TablaRegiones']);
  }
  redireccionTablaComunas()
  {
    this.router.navigate(['TablaComunas']);
  }
  redireccionTablaOficinas()
  {
    this.router.navigate(['TablaOficinas']);
  }
  redireccionTablaBodegas()
  {
    this.router.navigate(['TablaBodegas']);
  }
  redireccionTablaClientes()
  {
    this.router.navigate(['TablaClientes']);
  }
  redireccionTablaUsuarios()
  {
    this.router.navigate(['TablaUser']);
  }
  redireccionTablaLockers()
  {
    this.router.navigate(['TablaLockers']);
  }
  redireccionTablaCasilleros()
  {
    this.router.navigate(['TablaCasilleros']);
  }
  redireccionTablaUsuariosOfi()
  {
    this.router.navigate(['TablaUsuariosOfi']);
  }
  redireccionHome()
  {
    this.router.navigate(['']);
  }

}
