<div class="row">
  <div class="col-12 text-center mt-5">
    <app-locker></app-locker>
    <div class="btnes-mant">
      <button class="btn btnl" (click)="Abrirtodos()">Abrir Todos</button>
      <button class="btn btnl" (click)="Reiniciar()">Reiniciar</button>
      <button class="btn btnl" (click)="Apagar()">Apagar</button>
    </div>
  </div>
</div>
<button class="btn btnl btnv" (click)="volver()">
  Volver
</button>

