import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class TarjetaLockerService {

  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) { }

  async Conectar(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['PuertaAPI']}/ActivarPuerta`;

    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async AbrirPuerta(request): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['PuertaAPI']}/AbrirPuerta`;

    try {
      return await this.httpClient.post(url,request, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async DibujoCasillero(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const locker = API_URL['locker'];

    try {
      return locker;
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async AbrirTodasPuerta(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['PuertaAPI']}/AbrirTodasPuerta`;

    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async Apagar(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['PuertaAPI']}/Apagar`;

    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async Reiniciar(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['PuertaAPI']}/Reiniciar`;

    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async EstatusTodasPuerta(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['PuertaAPI']}/EstatusTodasPuerta`;

    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

}
