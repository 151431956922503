<!-- <div class="">
    <div class="row separacionL">
        <div *ngFor="let columns of Locker let index = index" class="columnalocker">
            <div *ngFor="let casillero of columns.Casilleros let index = index">
                <div class="casilleroLocker" (click)="AbrirLocker(casillero.id)"
                    [ngClass]="{ 'CaXS': casillero.tama == 'xs','CaS': casillero.tama == 's','CaM': casillero.tama == 'm','CaL': casillero.tama == 'l','CaEquipo': casillero.tama == 'xl'}">
                    <div class="idcasi">{{casillero.id}}</div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="d-flex cntr-stado-locker">
    <div class="font-weight-bold">
        Vista Online Locker
    </div>
</div>
<div class="d-flex flex-column marginlockr" >
    <div class="row separacionL">
        <div *ngFor="let columns of Locker let indexi = index" class="columnalocker border-t border-b">
            <div *ngFor="let casillero of columns.Casilleros let indexj = index"
                [ngClass]="{'border-f': indexi == 0,'border-l': indexi == Locker.length-1}">
                <div class="casilleroLocker" (click)="AbrirLocker(casillero.id)" *ngIf="casillero.ocupado"
                    [ngClass]="{ 'CaOXS': casillero.tama == 'xs','CaOS': casillero.tama == 's','CaOM': casillero.tama == 'm','CaOL': casillero.tama == 'l','CaOXL': casillero.tama == 'xl','CaEquipo': casillero.tama == 'xxl'}">
                    <div class="idcasi">{{casillero.id}}</div>
                </div>
                <div class="casilleroLocker" (click)="AbrirLocker(casillero.id)" *ngIf="!casillero.ocupado"
                    [ngClass]="{ 'CaXS': casillero.tama == 'xs','CaS': casillero.tama == 's','CaM': casillero.tama == 'm','CaL': casillero.tama == 'l','CaXL': casillero.tama == 'xl','CaEquipo': casillero.tama == 'xxl'}">
                    <div class="idcasi">{{casillero.id}}</div>
                </div>
            </div>
        </div>
    </div>
                
    <div class="d-flex cntr-stado-locker">
        <div class="d-flex w-50 align-items-center justify-content-center">
            <div class="Libre">
            </div>
            <div class="font-weight-bold">
                Disponible
            </div>
        </div>

        <div class="d-flex w-50 align-items-center justify-content-center">
            <div class="Ocupado">
            </div>
            <div class="font-weight-bold">
                No Disponible
            </div>
        </div>
    </div>
</div>