import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiLockerService {
  
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });


  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) { }

  async TraerPedidos(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    //console.log(API_URL);
    
    const url = `${API_URL['BaseAPI']}/pedido`;
    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async TraerUsuarios(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}/usuario`;
    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async CargarPedidos(pedido): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}/pedido/registrarCarga`;
    try {
      return await this.httpClient.post(url,pedido, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async registrarRetiro(pedido): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}/pedido/registrarRetiro`;
    try {
      return await this.httpClient.post(url,pedido, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  

}
