import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { UserIdleModule } from 'angular-user-idle';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from './modules/shared/shared.module';
import { CustomerService } from './customerservice';

import { TarjetaLockerService } from './core/services/http/tarjeta-locker.service';
import { ApiLockerService } from './core/services/http/api-locker.service';

// PrimeNg

import {DropdownModule} from 'primeng/dropdown';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import {MenuItem} from 'primeng/api';



import { AppComponent } from './app.component';
import { KeyboardComponent } from './custom/keyboard/keyboard.component';
import { HomeComponent } from './modules/home/home.component';
import { AdminComponent } from './modules/admin/pages/admin.component';
import { LoginComponent } from './modules/admin/components/login/login.component';
import { MantenedorComponent } from './modules/admin/components/mantenedor/mantenedor.component';
import { CienteComponent } from './modules/ciente/ciente.component';
import { UsuarioComponent } from './modules/usuario/usuario.component';
import { EscanRutComponent } from './modules/usuario/escan-rut/escan-rut.component';
import { SeleccionEmpresaComponent } from './modules/usuario/seleccion-empresa/seleccion-empresa.component';
import { LoginUsuarioComponent } from './modules/login-usuario/login-usuario.component';
import { TablaUsuariosComponent } from './modules/tabla-usuarios/tabla-usuarios.component';

import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import { TablaPedidosComponent } from './modules/tabla-pedidos/tabla-pedidos.component';
import { VistaGeneralComponent } from './modules/vista-general/vista-general.component';
import { VistaModalComponent } from './modules/vistaModal/vista-modal/vista-modal.component';
import { TablaModalComponent } from './modules/tabla-pedidos/tabla-modal/tabla-modal.component';
import { UsuariosModalComponent } from './modules/tabla-usuarios/usuarios-modal/usuarios-modal.component';
import { DatePipe } from '@angular/common';
import { PesoChilenoPipe } from './core/pipes/peso-chileno.pipe';
import { DataTablesModule } from 'angular-datatables';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DatatableComponent } from './modules/reportes/components/datatable/datatable.component';
import { HeaderTableComponent } from './modules/reportes/components/header-table/header-table.component';
import { ModalLogComponent } from './modules/reportes/components/modal-log/modal-log.component';
import { ReporteCierreComponent } from './modules/reportes/components/reporte-cierre/reporte-cierre.component';
import { ReporteServicesComponent } from './modules/reportes/components/reporte-services/reporte-services.component';





@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PesoChilenoPipe,
    KeyboardComponent,
    AdminComponent,
    LoginComponent,
    MantenedorComponent,
    CienteComponent,
    UsuarioComponent,
    EscanRutComponent,
    SeleccionEmpresaComponent,
    LoginUsuarioComponent,
    TablaUsuariosComponent,
    TablaPedidosComponent,
    VistaGeneralComponent,
    VistaModalComponent,
    TablaModalComponent,
    UsuariosModalComponent,
    ReporteCierreComponent,
    ModalLogComponent,
    HeaderTableComponent,
    ReporteServicesComponent,
    DatatableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    UserIdleModule.forRoot({ idle: 240, timeout: 60 }),
    TableModule,
    ReactiveFormsModule,
    AccordionModule,
    DropdownModule,
    FormsModule,
    ModalModule.forRoot(),
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule,
    InputTextModule,
    ProgressBarModule,
    ToastModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    PaginationModule.forRoot(),
    DataTablesModule
  ],
  exports: [
    PesoChilenoPipe,
  ],
  entryComponents: [KeyboardComponent],
  providers: [TarjetaLockerService, ApiLockerService, CustomerService, DatePipe, PesoChilenoPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
