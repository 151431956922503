import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigService } from 'src/app/core/services/config/config.service';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { PruebasService } from '../../core/services/http/pruebas.service';
import {Pedidos, RepAsignado} from '../../customer';
import {SweetAlertService} from '../../core/services/sweet-alert/sweet-alert.service';
import {TablaModalComponent} from '../tabla-pedidos/tabla-modal/tabla-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabla-pedidos',
  templateUrl: './tabla-pedidos.component.html',
  styleUrls: ['./tabla-pedidos.component.scss']
})
export class TablaPedidosComponent implements OnInit {

   myGroup: FormGroup;
   registerForm: FormGroup;
   pedidos: Pedidos[]
   pedidosV2: [] = [];
   datosInterface: RepAsignado;

  constructor(private PruebasService: PruebasService,private SweetAlertService: SweetAlertService, private configService: ConfigService, 
    private httpClient: HttpClient, private modalService: ModalService, private formBuilder: FormBuilder, private router: Router) { }

  async ngOnInit() {
    var datos = {
      "idLocker": 5,
      "idOficina": 5
    }
    var response = await this.PruebasService.TraerAgignados(datos);
    if(response['status']){
        this.pedidosV2 = response['dataCas'];
        console.log("Pedidos:", this.pedidosV2);
    }
  }

  volver() {
    this.router.navigate(['MenuOpciones']);
  }

  iniciarDato()
  {
    this.myGroup = new FormGroup({
      passWrd : new FormControl(),
      idLock : new FormControl(),
      idOfic : new FormControl()
    });
  }

  async datosAEnviar()
  {
    this.myGroup = new FormGroup({
      idLo : new FormControl(),
      idOf : new FormControl()
    });
  }

  Seleccionar(item: RepAsignado, e: any)
  {
    let link = item.idpedido;
    console.log('Id Pedido: ',link);
    const opcion = {class: 'w-50', initialState: {RepAsignado: e}}
    localStorage.setItem("idpedido", link.toString())
    this.modalService.openModal(TablaModalComponent, opcion);

  }



  

 


}
