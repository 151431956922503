import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { Usuarios } from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UsuariosModalComponent } from '../tabla-usuarios/usuarios-modal/usuarios-modal.component';

@Component({
    selector: 'app-tabla-usuarios',
    templateUrl: './tabla-usuarios.component.html',
    styleUrls: ['./tabla-usuarios.component.scss']
})
export class TablaUsuariosComponent implements OnInit {

    usuarios: Usuarios[]
    usuariosV2: [] = [];
    myGroup: FormGroup;
    datosInterface: Usuarios;
    selectedProducts: Usuarios[];
    modalRef: BsModalRef;

    loading;


    constructor(private PruebasService: PruebasService, private modalService: ModalService, private Router: Router, private SweetAlertService: SweetAlertService) { }

    async ngOnInit() {
        this.iniciarDatos()
        this.loading = true;
        this.llenarTabla();
        this.loading = false;
    }
    volver() {
        this.Router.navigate(['MenuOpciones']);
    }

    iniciarDatos() {
        this.myGroup = new FormGroup({
            sRutUsuario: new FormControl(),
            sNombre: new FormControl(),
            sPass: new FormControl(),
            habil: new FormControl()
        });
    }

    async traerdatos(datos) {
        console.log(datos);
        var reqUsuario = {
            "acc": "U",
            "dniUsu": datos.sDniUsu,
            "nomUsu": datos.sNombreUsu,
            "pass": datos.sPassUsu,
            "hab": datos.bHab
        }
        var resp = await this.PruebasService.ActualizarUsuarios(reqUsuario);
        if (resp["status"]) {
            this.SweetAlertService.swalSuccess("Usuario Administrativo Actualizado Correctamente");
            this.usuariosV2 = resp['data'];
            console.log(resp['data']);
        }
        else {
            this.SweetAlertService.swalError("Intente nuevamente");
        }
    }

    async agregarDatos(datos) {
        console.log(datos);
        var reqUsuario = {
            "acc": "N",
            "dniUsu": datos.sDniUsu,
            "nomUsu": datos.sNombreUsu,
            "pass": datos.sPassUsu,
            "hab": datos.bHab
        }
        var resp = await this.PruebasService.ActualizarUsuarios(reqUsuario);
        if (resp["status"]) {
            this.usuariosV2 = resp['data'];
            console.log(resp['data']);

        }
        else {

        }
    }


    async deleteSelected(datos) {
        console.log(datos);
        var reqUsuario = {
            "acc": "U",
            "dniUsu": datos.sDniUsu,
            "nomUsu": datos.sNombreUsu,
            "pass": datos.sPassUsu,
            "hab": !datos.bHab
        }
        var resp = await this.PruebasService.ActualizarUsuarios(reqUsuario);
        if (resp["status"]) {
            this.SweetAlertService.swalSuccess("Accion realizada Correctamente");
            this.usuariosV2 = resp['data'];
            console.log(resp['data']);
        }
        else {
        }
    }

    async llenarTabla() {
        let response = await this.PruebasService.TraerUsuario();
        if (response['status']) {
            this.usuariosV2 = response['dataUsu'];
            console.log("Usuarios:", this.usuariosV2);
        }
    }

    Seleccionar() {
        const opcion = { class: 'w-50', initialState: { regiones: this.usuariosV2 } }
        this.modalRef = this.modalService.openModal(UsuariosModalComponent, opcion);
        this.modalRef.content.onClose.subscribe((result) => {
            console.log('result: ', result);
            if (result) {
                this.llenarTabla()
            }

        });
    }
}
