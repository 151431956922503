import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { login } from 'src/app/customer';
import {RutService} from 'src/app/core/services/http/rut.service';
import { DataProcessService } from '../../core/services/miscellaneous/data-process.service';

@Component({
  selector: 'app-login-usuario',
  templateUrl: './login-usuario.component.html',
  styleUrls: ['./login-usuario.component.scss']
})
export class LoginUsuarioComponent implements OnInit {


  rutValid: boolean;
  login: login[] = [];
  registerForm: FormGroup;
  myGroup: FormGroup;
  email = new FormControl('');
  password = new FormControl('');
  usuarios = null;
  rut = '';
  nombreUsuario = '';
  passUsuario = '';
  estado = true;
  submited: boolean;
  data: import("../../core/models/data-process").DataProcess;
  
  
  constructor(
    private router: Router, 
    private SweetAlertService: SweetAlertService,
    private PruebasService: PruebasService,
    private dataProcessService: DataProcessService,
    private Router: Router,
    private RutService:RutService) { }

  ngOnInit(): void {
    this.dataProcessService.deleteDataProcess();
    this.iniciarDatos()
  }

  volver() {
    this.Router.navigate(['cierre']);
  }

  salir()
  {
    this.Router.navigate(['']);
  }

  iniciarDatos()
  {
    this.myGroup = new FormGroup({
      usuarioRut: new FormControl(),
      usuarioPass: new FormControl('',[Validators.required])
     }); 
  }

  get f() { return this.registerForm.controls; }




  async ValidaLogin(){

    this.submited = true;

    if(!this.rutValid || !this.myGroup.valid)
    {
      return;
    }

    let datosInterface : login = {
      rut: this.myGroup.value.usuarioRut.replaceAll('.','').replace('-',''),
      password: this.myGroup.value.usuarioPass
    }
    await this.SweetAlertService.swalLoading();
    console.log('FORMULARIO: ', datosInterface);
    let datosRetornados = await this.PruebasService.loginUsuario(datosInterface)
    this.dataProcessService.addDataProcess('datos', datosInterface.rut);
    console.log('RESPONSE: ',datosRetornados);
    this.data = this.dataProcessService.getDataProcess();
    console.log('RUT: ', this.data["datos"]);
    
    var estadoBtn = true;
    if(datosRetornados.status == true)
    {
      estadoBtn = false;
      this.SweetAlertService.swalSuccess("¡Bienvenido nuevamente!");
      this.volver()
    }
    else if (datosRetornados.code == 401)
    {
      await this.SweetAlertService.swalError("¡Error, Usuario o contraseña incorrectos!");
      window.location.reload()
    }
    else if (datosRetornados.code == 404)
    {
      await this.SweetAlertService.swalError("¡Error, Los campos no pueden quedar vacíos!");
      window.location.reload()
    }
    else{
      await this.SweetAlertService.swalError("¡Error, Por favor intente nuevamente!");
      window.location.reload()
    }
  }

  validaRut(): void {

    console.log(this.myGroup.value.usuarioRut);
    
    this.rutValid = this.RutService.validarRut(this.myGroup.value.usuarioRut.replace(/\./g, '').replace('-', ''));
    this.myGroup.patchValue({ usuarioRut: this.RutService.formateaRut(this.myGroup.value.usuarioRut) });
  }

  

  TablaUser(): void {
    //e.preventDefault();
    this.router.navigate(['cierre']);
  }

}
